import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useBoolean } from '../../utils/hooks';
import Layout from '../../components/layout';
import { forUsPages } from '../../data/data';
import loadable from '@loadable/component';

const SubMenu = loadable(() => import('../../components/subMenu'));
const Grid = loadable(() => import('../../components/grid'));
const CardWithImage = loadable(() => import('../../components/card-with-image'));
const PhotoSwipeWrapper = loadable(() => import('../../components/photoSwipe'));

const Awards = ({ data, location }) => {
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [coverImage, setCoverImage] = useState({
    src: '',
    h: 0,
    w: 0
  });
  const awards = data.allMarkdownRemark.edges;
  const handleOpen = image => {
    setCoverImage({
      src: image.src,
      h: image.presentationHeight,
      w: image.presentationWidth
    });

    showModal();
  };

  const metaData = {
    title: 'Награди'
  };

  return (
    <Layout metaData={metaData}>
      <SubMenu location={location.pathname} sectionItems={forUsPages} />
      <Grid>
        {awards.map((item, i) => {
          const award = item.node.frontmatter;
          return (
            <CardWithImage
              key={i}
              title={award.info}
              url={award.project}
              image={award.coverImage.childImageSharp.fluid}
              onOpen={() => {
                handleOpen(award.coverImage.childImageSharp.fluid);
              }}
            />
          );
        })}
      </Grid>
      <PhotoSwipeWrapper isOpen={isVisible} items={[coverImage]} onClose={hideModal} />
    </Layout>
  );
};

export default Awards;

export const query = graphql`
  query awards {
    allMarkdownRemark(filter: { fields: { slug: { regex: "/за-нас/награди/" } } }) {
      edges {
        node {
          id
          frontmatter {
            info
            project
            coverImage {
              childImageSharp {
                ...largeGalleryImage
              }
            }
          }
        }
      }
    }
  }
`;
